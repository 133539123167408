import { Link } from "gatsby";
import * as React from "react"
import { Form } from "react-bootstrap";
import requestContent from "../../../content/requestPageContent.json"
import { CustomButton } from "../../Components/CustomButton";
import { CustomInput, CustomMultiSelect,  CustomTextarea } from "../../Components/CustomInput";
import { FooterComponent } from "../../Components/FooterComponent";
import { LEGAL_PAGE } from "../../constant/routes";
import Countries from '../../../content/countries.json'
import { useForm, ValidationError } from '@formspree/react';


import SuccessCard from "../../Components/SuccessCard";
import LoaderCard from "../../Components/Loader";


const RequestIndex = () => {
     const [state, handleSubmit] = useForm("mgedreqo");
     console.log(handleSubmit)
     if (state.succeeded) {
         return <SuccessCard />
     }



    const propductOptions = [
        { value: 'Oil & Gas Transportation', label: 'Oil & Gas Transportation' },
        { value: 'Project Cost Management', label: 'Project Cost Management' },
        { value: 'HR Management', label: 'HR Management' },
      ]
  

      const EmployeeOptions = [
        { value: 'Just me', label: 'Just me' },
        { value: '2-10', label: '2-10' },
        { value: '51-250', label: '51-250' },
        { value: '251-500', label: '251-500' },
        { value: '501-1000', label: '501-1000' },
        { value: '1001-5000', label: '1001-5000' },
        { value: '5001-10,000', label: '5001-10,000' },
        { value: '10,000+', label: '10,000+' }
      ]

      

      const CountryOptions = Countries.sort((a, b) => a.name.localeCompare(b.name)).map((item) => 
            ({ value: item.name, label: item.emoji + ' ' + item.name  })
            )


     const FormComponent = () => (
              <form  onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <CustomInput label={requestContent.FormLabels[0]} type="email" name="Email" hasRequired />
                            <ValidationError 
                                prefix="Email" 
                                field="email"
                                errors={state.errors}
                            />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <CustomInput hasRequired label={requestContent.FormLabels[1]} name="Company"/>
                            <ValidationError 
                                prefix="Company" 
                                field="company"
                                errors={state.errors}
                            />
                    </div>
                </div>
                <div className="row" style={{marginBlock: 25}}>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <CustomInput hasRequired  label={requestContent.FormLabels[2]} name="Firstname"/>
                            <ValidationError 
                                prefix="Firstname" 
                                field="firstname"
                                errors={state.errors}
                            />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <CustomInput hasRequired type="tel" label={requestContent.FormLabels[3]} name="Lastname"/>
                            <ValidationError 
                                prefix="Lastname" 
                                field="lastname"
                                errors={state.errors}
                            />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">

                    <CustomMultiSelect label={requestContent.FormLabels[4]} hasRequired name="Employee Count" options={EmployeeOptions} isMulti={false}/>
                        <ValidationError 
                                prefix="Employee Count" 
                                field="employee count"
                                errors={state.errors}
                            />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <CustomInput hasRequired label={requestContent.FormLabels[5]} name="Phone Number"/>
                            <ValidationError 
                                prefix="Phone Number" 
                                field="phone number"
                                errors={state.errors}
                            />
                    </div>
                </div>
                <div className="row" style={{marginBlock: 25}}>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                    <CustomMultiSelect label="Country" hasRequired name="Country" options={CountryOptions} />
                        <ValidationError 
                                prefix="Country" 
                                field="country"
                                errors={state.errors}
                            />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <CustomInput hasRequired label={requestContent.FormLabels[7]} name="Region/State"/>
                            <ValidationError 
                                prefix="Region/State" 
                                field="region/state"
                                errors={state.errors}
                            />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                    
                            <CustomMultiSelect label="Select Product(s)" hasRequired name="product" options={propductOptions} isMulti={true}/>
                            
                        <ValidationError 
                                prefix="Product" 
                                field="product"
                                errors={state.errors}
                            />
                    </div>
                    
                </div>
                <div className="row" style={{marginBlockStart: 25}}>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <CustomTextarea hasRequired placeholder="Write your message..." label={requestContent.FormLabels[8]} name="Basic Needs"/>
                        <ValidationError 
                                prefix="Basic Needs" 
                                field="basic nedds"
                                errors={state.errors}
                            />
                    </div>

                </div>
                <div className="row form_text">
                    <Form.Text id="terms and conditions">
                        By submitting the form, I agree to the  <Link to={LEGAL_PAGE}>Privacy Policy</Link>.
                    </Form.Text>

                </div>
                <div className="required_text mt-2" style={{marginBlock: 25}}>
                    <sup className="text-danger">*</sup><span>{requestContent.FormLabels[9]}</span>
                </div>
                <div className="mt-5">
                    <div className="d-flex justify-content-center primaryBtn">
                        <CustomButton type="submit" text={requestContent.Buttontext} disabled={state.submitting ? true : false}/>
                    </div>
                </div>
            </form>
     )       

    return (
        <>
            <section className="request">
                <div className="request__content container">
                    <div className="row">
                        <div className="col-sm-12 col-md-7 col-lg-5 mx-auto request__content-text">
                            <h5>{requestContent.HeaderText[0]}</h5>
                            <p>{requestContent.HeaderText[1]}</p>
                        </div>
                    </div>
                    <div className="request__form">
                        <div className="row ">
                            <div className="col-sm-12 col-lg-7 col-lg-7 mx-auto">
                             {state.submitting ? (<LoaderCard />) : (<FormComponent />)}
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            <FooterComponent />
        </>
    );
};

export default RequestIndex;
