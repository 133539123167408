import * as React from "react"
import HeaderComponent from "../../Components/HeaderComponent";
import Layout from '../../Components/Layout';
import Seo from "../../Components/Seo";
import Request from '../../modules/Request'

const index = () => {
    return (
        <>
           
<Seo title="Request for demo | Webbermill"/>
            <Layout className="request">
                <title>Request for demo</title>
                <HeaderComponent hasProduct/>
                <Request />
            </Layout>
        </>
    );
};

export default index;